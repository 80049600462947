// methods
import { getSchoolInfo } from '@/api/basic';

// components
import basicHeader from '@/components/backStageHeader';

// 工具
import { formatDateTime } from '@/utils/common';

export default {
  name: 'School',
  props: {},
  data() {
    return {
      schoolData: {},
      visible: false,
      formData: {
        schoolName: '',
        schoolType: '',
        schoolTypeName: '',
        schoolShortName: '',
        beginYear: '',
      },
    };
  },
  components: {
    basicHeader,
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getSchoolShow();
    },

    async getSchoolShow() {
      const res = await getSchoolInfo();

      if (res.status === 0) {
        const { periodNames, validBeginTime, validEndTime } = res.result;

        this.schoolData = {
          ...res.result,
          periodNames: periodNames.split(',').join('、'),
          validBeginTime: formatDateTime(validBeginTime, 'yyyy年MM月dd日'),
          validEndTime: formatDateTime(validEndTime, 'yyyy年MM月dd日'),
        };
      }
    },

    handleAdd() {
      this.formData = {
        schoolKey: this.schoolData.schoolKey,
        schoolId: this.schoolData.schoolId,
        schoolName: this.schoolData.schoolName,
        schoolType: this.schoolData.schoolType,
        schoolTypeName: this.schoolData.schoolTypeObj ? this.schoolData.schoolTypeObj.typeName : '',
        schoolShortName: this.schoolData.schoolShortName,
        beginYear: this.schoolData.beginYear,
      };
      this.visible = true;
    },

    handleSuccess() {
      this.getSchoolShow().then(() => {
        this.visible = false;
      });
    },
  },
};
